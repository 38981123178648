<template>
  <the-header>
  </the-header>
  <section class="section">
    <form @submit.prevent="submitForm" :style="formStyle">
      <h1>{{ tr('My Profile') }}</h1>
      <div id="tutorial-profile-step-target">
        <div class="form-control" :class="{ invalid: displayNameValidity === 'invalid' || !isUniqueDisplayName }">
          <label for="user-name">
            {{ tr('Your Displayed Name (as shown in URLs and in Chat sessions)') }}
          </label>
          <input id="user-name" name="user-name" type="text" v-model.trim="preferredDisplayName"
            :style="formElementStyle" />
          <p class="error" v-if="displayNameValidity === 'invalid'">
            {{ tr('Name cannot contain any of the following characters:') }}
            <br>! @ # $ % ^ &amp; * [ ] { } ; : ' " &lt; &gt; \ / |
          </p>
          <p class="error" v-if="!isUniqueDisplayName">
            {{ tr('Your Displayed Name is not unique and will not be displayed in URLs.') }}
          </p>
        </div>
        <div class="form-control">
          <label for="preferredLanguage">{{ tr('Your Preferred Language') }} </label>
          <select role="preferredLanguage" id="preferredLanguage" name="preferredLanguage" v-model="preferredLanguage"
            :style="formElementStyle">
            <option v-for="language in languages" :key="language.id" :value="language.id">
              {{ tr(language.name) }}
              <span v-if="tr(language.name) !== language.name">({{ language.name }})</span>
            </option>
          </select>
        </div>
        <div class="form-control" v-if="featureToggles.chooseAdditionalThemes">
          <label for="preferredLightTheme">{{ tr('Your Preferred Light Theme') }} </label>
          <select role="preferredLightTheme" id="preferredLightTheme" name="preferredLightTheme"
            v-model="preferredLightTheme" :style="formElementStyle">
            <option v-for="theme in lightThemes" :key="theme" :value="theme">
              {{ tr(theme) }}
              <span v-if="tr(theme) !== theme">({{ theme }})</span>
            </option>
          </select>
        </div>
        <div class="form-control" v-if="featureToggles.chooseAdditionalThemes">
          <label for="preferredDarkTheme">{{ tr('Your Preferred Dark Theme') }} </label>
          <select role="preferredDarkTheme" id="preferredDarkTheme" name="preferredDarkTheme" v-model="preferredDarkTheme"
            :style="formElementStyle">
            <option v-for="theme in darkThemes" :key="theme" :value="theme">
              {{ tr(theme) }}
              <span v-if="tr(theme) !== theme">({{ theme }})</span>
            </option>
          </select>
        </div>
        <div class="form-control">
          <label for="preferredTimeZone">{{ tr('Time Zone') }} </label>
          <select role="preferredTimeZone" id="preferredTimeZone" name="preferredTimeZone" v-model="preferredTimeZone"
            :style="formElementStyle">
            <option v-for="timeZone in timeZones" :key="timeZone.id" :value="timeZone.id">
              {{ tr(timeZone.name) }}
            </option>
          </select>
        </div>
        <div class="form-control shifted-left">
          <check-box id="confirmBeforeDeletingSession" :is-checked="preferredConfirmBeforeDeletingSession"
            @checked="onConfirmBeforeDeletingSession">
            <label class="checkbox-label" :style="fontStyle" for="confirmBeforeDeletingSession">
              {{ tr('Show Confirmation Before Deleting Session') }}
            </label>
          </check-box>
        </div>
      </div>
      <div class="form-control">
        <button :style="formButtonStyle" @click.prevent="onSignOut">{{ tr('Sign Out') }}</button>
        <button :style="formButtonStyle" style="margin-left:2rem;" @click.prevent="startTutorial">
          {{ tr('Start Tutorial') }}
        </button>
        <button :style="formButtonStyle" style="margin-left:2rem;" @click.prevent="onReturnToSession">{{ tr('Return')
        }}</button>
      </div>
      <p v-if="getEnv('VUE_APP_PRODUCT_VERSION_SEMVER')">{{ tr('Software Version:') }} {{
        getEnv('VUE_APP_PRODUCT_VERSION_SEMVER')
      }}</p>
    </form>
  </section>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { isThemeLight, nameIsValidRE, onSignOut, themeColors, timeZones, useDebouncedRef } from '@/common/shared.js'
import CheckBox from '@/components/ui/CheckBox/CheckBox.vue'
import router from '@/router'
import getEnv from '@/utils/env'

export default {
  components: {
    CheckBox,
  },
  setup() {
    const store = useStore()

    const allLanguages = inject('languages')
    const tr = inject('tr')
    const featureToggles = computed(() => store.getters.featureToggles)

    const languages = Object.keys(allLanguages).map((name) => { return { id: allLanguages[name], name: name } })

    const formStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
        border: `1px solid ${themeColors[store.getters.currentThemeName].modalTextColor}`,
      }
    })

    const formButtonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].toolInputBackgroundColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
        border: `1px solid ${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    const formElementStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
        border: `1px solid ${themeColors[store.getters.currentThemeName].inputElementBorderColor}`,
      }
    })

    const preferredLanguage = ref(store.getters.profile.language)
    watch(preferredLanguage, (language) => {
      if (storedLanguage.value !== language) {
        store.dispatch('changeLanguage', { language, publish: true })
      }
    })
    const storedLanguage = computed(() => store.getters.profile.language)
    watch(storedLanguage, (language) => {
      if (preferredLanguage.value !== language) { preferredLanguage.value = language }
    })

    const lightThemes = Object.keys(isThemeLight)
    const darkThemes = Object.keys(themeColors).filter((theme) => !lightThemes.includes(theme))

    const preferredLightTheme = ref(store.getters.profile.lightThemeName)
    watch(preferredLightTheme, (lightThemeName) => {
      if (storedLightTheme.value !== lightThemeName) {
        store.dispatch('changeCurrentThemeName', { themeName: lightThemeName, publish: true })
        store.dispatch('changeLightTheme', { lightThemeName, publish: true })
      }
    })
    const storedLightTheme = computed(() => store.getters.profile.lightThemeName)
    watch(storedLightTheme, (lightThemeName) => {
      if (preferredLightTheme.value !== lightThemeName) { preferredLightTheme.value = lightThemeName }
    })

    const preferredDarkTheme = ref(store.getters.profile.darkThemeName)
    watch(preferredDarkTheme, (darkThemeName) => {
      if (storedDarkTheme.value !== darkThemeName) {
        store.dispatch('changeCurrentThemeName', { themeName: darkThemeName, publish: true })
        store.dispatch('changeDarkTheme', { darkThemeName, publish: true })
      }
    })
    const storedDarkTheme = computed(() => store.getters.profile.darkThemeName)
    watch(storedDarkTheme, (darkThemeName) => {
      if (preferredDarkTheme.value !== darkThemeName) { preferredDarkTheme.value = darkThemeName }
    })

    const displayNameValidity = ref('pending')

    const preferredDisplayName = useDebouncedRef(store.getters.profile.displayName, 200)
    watch(preferredDisplayName, (displayName) => {
      if (displayName && !nameIsValidRE.test(displayName)) {
        displayNameValidity.value = 'invalid'
        return
      }
      displayNameValidity.value = 'valid'
      if (storedDisplayName.value !== displayName) {
        store.dispatch('changeDisplayName', { displayName, publish: true })
        store.getters.sessionsOwnedByMe.forEach((session) => {
          const msg = {
            ownerId: session.ownerId,
            sessionId: session.id,
            sessionOwnerName: displayName,
            userId: store.getters.userId,
            publish: true,
          }
          store.dispatch('setSessionOwnerName', msg)
        })
      }
    })
    const storedDisplayName = computed(() => store.getters.profile.displayName)
    watch(storedDisplayName, (displayName) => {
      if (preferredDisplayName.value !== displayName) { preferredDisplayName.value = displayName }
    })

    const preferredTimeZone = ref(store.getters.profile.timeZone)
    watch(preferredTimeZone, (timeZone) => {
      if (storedTimeZone.value !== timeZone) {
        store.dispatch('changeTimeZone', { timeZone, publish: true })
      }
    })
    const storedTimeZone = computed(() => store.getters.profile.timeZone)
    watch(storedTimeZone, (timeZone) => {
      if (preferredTimeZone.value !== timeZone) {
        preferredTimeZone.value = timeZone
      }
    })

    const isUniqueDisplayName = computed(() => store.getters.profile.isUniqueDisplayName)

    const routeToMostRecentSession = computed(() => store.getters.mostRecentSessionUrlOrDashboard)
    const onReturnToSession = () => {
      router.push(routeToMostRecentSession.value)
    }

    const setupTutorial = inject('setupTutorial')
    const routeToDashboard = computed(() => store.getters.dashboardUrl)
    const startTutorial = setupTutorial(routeToDashboard.value, store, preferredLanguage)

    const checkboxOutline = computed(() => {
      return {
        outlineColor: isThemeLight[store.getters.currentThemeName] ? '#000' : '#fff',
      }
    })

    const fontStyle = computed(() => {
      return {
        fontFamily: store.getters.font,
      }
    })

    const labelStyle = computed(() => {
      return {
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const displayColor = computed(() => store.getters.checkboxColor)

    const onConfirmBeforeDeletingSession = (confirmBeforeDeletingSession) => {
      store.dispatch('changeConfirmBeforeDeletingSession', { confirmBeforeDeletingSession, publish: true })
    }

    const preferredConfirmBeforeDeletingSession = ref(store.getters.profile.confirmBeforeDeletingSession)
    watch(preferredConfirmBeforeDeletingSession, (confirmBeforeDeletingSession) => {
      if (storedConfirmBeforeDeletingSession.value !== confirmBeforeDeletingSession) {
        onConfirmBeforeDeletingSession(confirmBeforeDeletingSession)
      }
    })
    const storedConfirmBeforeDeletingSession = computed(() => store.getters.profile.confirmBeforeDeletingSession)
    watch(storedConfirmBeforeDeletingSession, (confirmBeforeDeletingSession) => {
      if (preferredConfirmBeforeDeletingSession.value !== confirmBeforeDeletingSession) {
        preferredConfirmBeforeDeletingSession.value = confirmBeforeDeletingSession
      }
    })

    return {
      checkboxOutline,
      darkThemes,
      displayColor,
      displayNameValidity,
      featureToggles,
      fontStyle,
      formButtonStyle,
      formElementStyle,
      formStyle,
      getEnv,
      isUniqueDisplayName,
      labelStyle,
      languages,
      lightThemes,
      onConfirmBeforeDeletingSession,
      onReturnToSession,
      onSignOut,
      preferredConfirmBeforeDeletingSession,
      preferredDarkTheme,
      preferredDisplayName,
      preferredLanguage,
      preferredLightTheme,
      preferredTimeZone,
      startTutorial,
      timeZones,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
.section {
  margin: 0 1rem 6rem 1rem;
  position: relative;
  top: 5rem;
  touch-action: none;
  display: block;
  text-align: left;
}

h1 {
  font-size: 1.5rem;
  margin-top: 0;
  text-align: center;
}

form {
  margin: auto;
  max-width: 40rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  padding-bottom: 12px;
  background-color: #ffffff;
}

.form-control {
  margin: 1.5rem 0;
}

.form-control.invalid input {
  outline: 2px solid red;
}

.form-control.invalid label {
  color: red;
}

p.error {
  color: red;
}

label {
  font-weight: bold;
}

h2 {
  font-size: 1rem;
  margin: 0.5rem 0;
}

input,
select {
  display: block;
  width: 100%;
  font: inherit;
  margin-top: 0.5rem;
}

select {
  width: auto;
  padding: 0.175rem;
  border-radius: 6px;
}

input[type='text'] {
  padding: 0.175rem;
  padding-left: 0.425rem;
  padding-right: 0.425rem;
  border-radius: 6px;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  width: auto;
  margin-right: 1rem;
}

input[type='checkbox']+label,
input[type='radio']+label {
  font-weight: normal;
}

button {
  font: inherit;
  font-size: 1rem;
  border-radius: 40px;
  margin: 0 1rem 0 0;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;


  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

.checkbox-label {
  vertical-align: middle;
}

.shifted-left {
  transform: translateX(-9px);
}
</style>
